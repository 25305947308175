import { BookingType } from "@/components/booking/types";
import { createSearchParamsFromFilters } from "./query-string-utils";
import { FilterInput, PtDurationType, PtLevelType } from "@/generated/client.generated";

export enum BookingRoutes {
  GRUPPETIME = "gruppetimer",
  GRUPPETIME_UKE = "gruppetimer/uke",
  SQUASH = "squash",
  CAGEBALL = "cageball",
  GOLFSIMULATOR = "golfsimulator",
  PT = "finn-personlig-trener",
  KLIPPEKORT = "klippekort",
  FYSIO = "fysio",
  LAKTATPROFIL = "laktatprofil",
  TRENINGSTESTER = "treningstester",
  VEILEDNING = "veiledning",
  BARNEPASS = "barnepass",
  BEHANDLER = "finn-behandler"
}

export enum MyPageRoutes {
  INFO = "informasjon",
  PROFIL = "profil",
  MYBOOKINGS = "bookinger",
  MEDLEMSKAP = "medlemskap",
  HVILEMEDLEMSKAP = "medlemskap/hvilemedlemskap",
  AVSLUTT_MEDLEMSKAP = "medlemskap/avslutt-medlemskap",
  VERDIKORT = "verdikort",
  VERDIKORT_FYLL_PAA_PAYKORT = "verdikort/fyll-paa-paykort", // TODO: queryparams
  BETALINGER = "faktura-og-betaling",
  SAMTYKKER = "samtykker",
  TRENINGSSTATISTIKK = "treningsstatistikk"
}

export enum KundeserviceRoutes {
  KONTAKT_VAARE_SENTER = "kontakt-vaare-senter",
  MEDLEMSSKAP = "medlemskap",
  NY_PAA_3T_APP = "/ny-pa-3t/3t-app",
  OFTE_STILTE_SPORSMAAL = "ofte-stilte-sporsmal",
  VILKAAR_OG_REGLER = "vilkar-og-regler",
  VILKAAR_OG_REGLER_BOOKINGREGLER = "vilkar-og-regler/bookingregler",
  VILKAAR_OG_REGLER_MEDLEMSVILKAAR = "vilkar-og-regler/medlemsvilkar"
}

export const paths = {
  activity(slug: string) {
    return `/trening/gruppetimer/${slug.toLowerCase()}`;
  },
  allActivities: "/trening/gruppetimer/alle-gruppetimer",
  allBookings: "/booking",
  bliMedlem: "/bli-medlem",
  bliMedlemPrefilled(customerTypeId?: string, centerId?: string, accountTypeId?: string) {
    if (!customerTypeId && !centerId && !accountTypeId) {
      return this.bliMedlem;
    }
    let url = `/bli-medlem?`;
    if (customerTypeId) {
      url = url + `customerTypeId=${customerTypeId}`;
    }
    if (centerId) {
      url = url + `mainCenterId=${centerId}`;
    }
    if (accountTypeId) {
      url = url + `accountType=${accountTypeId}`;
    }
    return url;
  },
  bliMedlemShortHand: (slug: string) => {
    return `/bli-medlem/${slug}`;
  },
  bliMedlemWithCode(code: string) {
    return `/bli-medlem/${code}`;
  },
  booking(type: BookingRoutes): string {
    return `/booking/${type}`;
  },
  bookingForBookingType(bookingType: BookingType, filters?: Array<FilterInput>): string {
    const queryParams = filters ? `?${createSearchParamsFromFilters(filters)}` : "";
    switch (bookingType) {
      case BookingType.GRUPPETIME: {
        return `/booking/${BookingRoutes.GRUPPETIME}${queryParams}`;
      }
      case BookingType.SQUASH: {
        return `/booking/${BookingRoutes.SQUASH}${queryParams}`;
      }
      case BookingType.CAGEBALL: {
        return `/booking/${BookingRoutes.CAGEBALL}${queryParams}`;
      }
      case BookingType.GOLFSIMULATOR: {
        return `/booking/${BookingRoutes.GOLFSIMULATOR}${queryParams}`;
      }
      case BookingType.PT: {
        return `/trening/personlig-trener/${BookingRoutes.PT}${queryParams}`;
      }
      case BookingType.VEILEDNING: {
        return `/booking/${BookingRoutes.VEILEDNING}${queryParams}`;
      }
      case BookingType.TRENINGSTESTER: {
        return `/booking/${BookingRoutes.TRENINGSTESTER}${queryParams}`;
      }
      case BookingType.BARNEPASS: {
        return `/booking/${BookingRoutes.BARNEPASS}${queryParams}`;
      }
      case BookingType.KLIPPEKORT: {
        return `/booking/${BookingRoutes.KLIPPEKORT}${queryParams}`;
      }
      case BookingType.ACTIVITIES: {
        return `/trening/gruppetimer/alle-gruppetimer${queryParams}`;
      }
      case BookingType.EVENTS: {
        return `/tjenester/arrangement/alle-arrangement${queryParams}`;
      }
      case BookingType.BEHANDLER: {
        return `/tjenester/3t-klinikken/${BookingRoutes.BEHANDLER}${queryParams}`;
      }
    }
  },
  bookingLandingPage(): string {
    return `/booking`;
  },
  bookingReceipt(orderId: string) {
    return `/booking/kvittering?id=${orderId}`;
  },
  bookingSummary(orderId: string, error?: string) {
    if (error) {
      return `/booking/oppsummering?id=${orderId}&error=${error}`;
    }
    return `/booking/oppsummering?id=${orderId}`;
  },
  bookinghistorikk: "/min-side/bookinger/historikk",
  event(slug: string, specificEvent?: { bookingId: string; centerId: string }) {
    if (specificEvent) {
      return `/tjenester/arrangement/${slug.toLowerCase()}?bookingId=${specificEvent.bookingId}&centerId=${
        specificEvent.centerId
      }`;
    }
    return `/tjenester/arrangement/${slug.toLowerCase()}`;
  },
  events: `/tjenester/arrangement/alle-arrangement`,
  glemtPassord: "/glemt-passord",
  gruppetime(activitySlug: string, specificActivity?: { bookingId: string; centerId: string }): string {
    if (specificActivity) {
      return `/trening/gruppetimer/${activitySlug}?bookingId=${specificActivity.bookingId}&centerId=${specificActivity.centerId}`;
    } else if (activitySlug) {
      return `/trening/gruppetimer/${activitySlug}`;
    }
    return `/trening/gruppetimer/alle-gruppetimer`;
  },
  gruppetimer: "/trening/gruppetimer",
  hjem: "/",
  infoPageForBookingType(bookingType: BookingType, serviceProductId?: number) {
    switch (bookingType) {
      // TODO: update paths that are commented out when we know where to go (se issue https://github.com/3T-Utvikling/3tno/issues/1436)
      case BookingType.SQUASH: {
        return `/trening/sportsaktiviteter/squash`;
      }
      case BookingType.CAGEBALL: {
        return `/trening/sportsaktiviteter/cageball`;
      }
      case BookingType.GOLFSIMULATOR: {
        return `/trening/sportsaktiviteter/golf`;
      }
      case BookingType.VEILEDNING: {
        return `/trening/treningsstudio/veiledning`;
      }
      case BookingType.TRENINGSTESTER: {
        return serviceProductId ? paths.infoPageForTreningsTest(serviceProductId) : undefined;
      }
      case BookingType.BARNEPASS: {
        return `/tjenester/barnepass`;
      }
      default: {
        return undefined;
      }
    }
  },
  infoPageForTreningsTest(serviceProductId: number) {
    switch (serviceProductId) {
      //LACTATETEST
      case 27586: {
        return "/tjenester/treningstester/laktattest";
      }
      //O2MAXTEST
      case 27583: {
        return "/tjenester/treningstester/vo2-max";
      }
      //LACTATEANDO2MAXTEST
      case 27585: {
        return "/tjenester/treningstester/laktattest";
      }
      //InBody
      case 5432: {
        return "/tjenester/treningstester/kroppsanalyse";
      }
      default: {
        return undefined;
      }
    }
  },
  instruktor(slug: string): string {
    return `/trenere/${slug}`;
  },
  instruktorer: "/trenere",
  klinikken: "/tjenester/3t-klinikken",
  klippekort: "/booking/klippekort",
  klippekortFiltered: (ptLevel?: PtLevelType, ptDuration?: PtDurationType) => {
    if (!ptLevel && !ptDuration) {
      return "/booking/klippekort";
    }
    let url = "/booking/klippekort?";
    if (ptLevel) {
      url = url + `PTLEVEL=${ptLevel}`;
    }
    if (ptDuration) {
      url = url + `PTDURATION=${ptDuration}`;
    }
    return url;
  },
  kundeservice(route: KundeserviceRoutes) {
    return `/kundeservice/${route}`;
  },
  kundeserviceLandingPage: "/kundeservice",
  login: "/api/auth/signin",
  medlemskap: "/medlemskap/vare-medlemskap",
  minSide(route: MyPageRoutes) {
    return `/min-side/${route}`;
  },
  minSideLandingPage: "/min-side",
  nyBruker: "/ny-bruker",
  personvernOgMarkedsforing() {
    return "/ansvar-og-policy/personvernerklaering";
  },
  ptInfo: "/trening/personlig-trener",
  ptKontakt(slug: string) {
    return `/trening/personlig-trener/kontakt/?pt=${slug}`;
  },
  pts: "/trening/personlig-trener/finn-personlig-trener",
  senter(slug: string): string {
    return `/treningssenter/${slug}`;
  },
  senters: "/treningssenter",
  trainer(slug: string) {
    return `/trenere/${slug}`;
  },
  trenNaa: "/tren-naa",
  trenNaaPrefilled(customerTypeId?: string, centerId?: string, accountTypeId?: string) {
    if (!customerTypeId && !centerId && !accountTypeId) {
      return this.trenNaa;
    }
    let url = `/tren-naa?`;
    if (customerTypeId) {
      url = url + `customerTypeId=${customerTypeId}`;
    }
    if (centerId) {
      url = url + `mainCenterId=${centerId}`;
    }
    if (accountTypeId) {
      url = url + `accountType=${accountTypeId}`;
    }
    return url;
  },
  trening: "/trening",
  vervEnVenn: "/medlemskap/verv-en-venn"
};

export const ROOT_URL = process.env.NEXT_PUBLIC_ROOT_URL;
