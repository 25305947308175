import ContentLoader from "react-content-loader";

import { classNames } from "@/utils/styling-utils";
import { capitalizeWord } from "@/utils/string-utils";
import { FormatBaseProps } from "./ui.model";

interface TextProps extends FormatBaseProps {
  capitalize?: boolean;
  gradient?: boolean;
  noWrap?: boolean;
  shadow?: boolean;
  truncate?: boolean;
  underline?: boolean;
  value: string;
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `text__${property}--${value}`;
  return className;
};

export const Text = ({
  align,
  capitalize,
  color,
  gradient,
  noWrap,
  shadow,
  size,
  style,
  truncate,
  underline,
  uppercase,
  value,
  weight
}: TextProps) => {
  const cssAlignClass = cssClasses("align", align);
  const cssColorClass = cssClasses("color", color);
  const cssSizeClass = cssClasses("size", size);
  const cssStyleClass = cssClasses("style", style);
  const cssWeightClass = cssClasses("weight", weight);

  return (
    <div
      className={classNames(
        "text",
        cssAlignClass,
        cssColorClass,
        cssSizeClass,
        cssStyleClass,
        cssWeightClass,
        noWrap && "text--no-wrap",
        shadow && "text--shadow",
        truncate && "text--truncate",
        underline && "text--underline",
        uppercase && "text--uppercase",
        gradient && "text--gradient"
      )}
    >
      {capitalize ? capitalizeWord(value) : value}
    </div>
  );
};

interface TextSkeletonProps {
  size?: "x-small" | "small" | "normal" | "large" | "x-large";
}
export const TextSkeleton = ({ size }: TextSkeletonProps) => {
  if (size === "x-small") {
    return (
      <ContentLoader viewBox="0 0 60 21" height={21} width={60}>
        <rect width="60" height="21" />
      </ContentLoader>
    );
  } else if (size === "small") {
    return (
      <ContentLoader viewBox="0 0 123 21" height={21} width={123}>
        <rect width="123" height="21" />
      </ContentLoader>
    );
  } else if (size === "large") {
    return (
      <ContentLoader height={52} width={200}>
        <rect height="52" width="100%" />
      </ContentLoader>
    );
  } else {
    return (
      <ContentLoader
        speed={2}
        width={360}
        height={84}
        viewBox="0 0 340 84"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
        <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
        <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
        <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
        <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
        <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
        <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
        <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
      </ContentLoader>
    );
  }
};
