export const Spinner = () => (
  <div className="spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="44"
      height="44"
      viewBox="0 0 80 80"
      preserveAspectRatio="xMidYMid"
      className="blocks-wrapper"
      aria-label="blocks-loading"
      data-testid="color-ring-svg"
      aria-busy="true"
      role="status"
    >
      <defs>
        <mask id="ldio-4offds5dlws-mask">
          <circle
            cx="40"
            cy="40"
            r="26"
            stroke="#fff"
            strokeLinecap="round"
            strokeDasharray="122.52211349000194 40.840704496667314"
            strokeWidth="9"
            transform="rotate(198.018 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              values="0 40 40;360 40 40"
              keyTimes="0;1"
              dur="1s"
              repeatCount="indefinite"
            ></animateTransform>
          </circle>
        </mask>
      </defs>
      <g mask="url(#ldio-4offds5dlws-mask)">
        <rect x="0" y="0" width="80" height="80" fill="#f3f3f3">
          <animate
            attributeName="fill"
            values="#f3f3f3;#ecebeb;#f3f3f3"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
            begin="-0.8s"
          ></animate>
        </rect>
      </g>
    </svg>
  </div>
);
