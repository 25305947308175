"use client";

import React, { ReactNode, SyntheticEvent } from "react";
import { classNames } from "@/utils/styling-utils";

import { Spinner } from "../ui/spinner";
import { getButtonTypeSuffix } from "./button-utils";
import ContentLoader from "react-content-loader";
import { WidthType } from "../layout/layout.model";
import { GAEventTypes } from "@/utils/ga-utils";

export type ButtonType = "primary" | "secondary" | "tertiary" | "neutral" | "primary-light" | "secondary-light";
export type ButtonSizeType = "big";

interface ButtonAllProps {
  /**
   * dataComponent is for Google Tag Manager
   */
  dataComponent?: string;
  /**
   * dataEvent is for Google Tag Manager
   */
  dataEvent?: GAEventTypes;
  /**
   * dataLabel is for Google Tag Manager
   */
  dataLabel?: string;
}

interface ButtonProps extends ButtonAllProps {
  ariaLabel?: string;
  buttonType?: ButtonType;
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  size?: ButtonSizeType;
  type?: "submit" | "reset" | "button" | undefined;
  width?: WidthType;
  wrap?: boolean;
}

export const Button = ({
  ariaLabel,
  buttonType,
  children,
  dataComponent,
  dataEvent,
  dataLabel,
  disabled,
  loading,
  onClick,
  size,
  type,
  width,
  wrap
}: ButtonProps) => {
  const buttonTypeSuffix = getButtonTypeSuffix(buttonType);

  return (
    <button
      aria-label={ariaLabel}
      className={classNames(
        "button",
        buttonTypeSuffix && `button${buttonTypeSuffix}`,
        disabled && "button--disabled",
        size && `button--size--${size}`,
        width && `button--width--${width}`
      )}
      data-component={dataComponent}
      data-event={dataEvent}
      data-label={dataLabel}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <ButtonVisibleArea
        buttonTypeSuffix={buttonTypeSuffix}
        dataComponent={dataComponent}
        dataEvent={dataEvent}
        dataLabel={dataLabel}
        size={size}
        width={width}
        wrap={wrap}
      >
        {loading && <Spinner />}
        {children}
      </ButtonVisibleArea>
    </button>
  );
};

// Nedenstående brukes også av LinkButton og FakeButton
interface ButtonVisibleAreaProps extends ButtonAllProps {
  buttonTypeSuffix: string;
  children: ReactNode;
  size?: ButtonSizeType;
  width?: WidthType;
  wrap?: boolean;
}

export const ButtonVisibleArea = ({
  buttonTypeSuffix,
  children,
  dataComponent,
  dataEvent,
  dataLabel,
  size,
  width,
  wrap
}: ButtonVisibleAreaProps) => (
  <div
    className={classNames(
      "button__visible",
      buttonTypeSuffix && `button${buttonTypeSuffix}__visible`,
      size && `button__visible--size--${size}`,
      width && `button--width--${width}`,
      wrap && `button__visible--wrap`
    )}
    data-component={dataComponent}
    data-event={dataEvent}
    data-label={dataLabel}
  >
    {children}
  </div>
);

export const ButtonSkeleton = () => {
  return (
    <ContentLoader height="44" width="100%">
      <rect height="100%" width="100%" />
    </ContentLoader>
  );
};
