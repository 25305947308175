import { FilterInput } from "@/generated/client.generated";
import { ReadonlyURLSearchParams } from "next/navigation";

//creates query string from filterValues. Will replace old query string
export const createSearchParamsFromFilters = (filters: Array<FilterInput>) => {
  const params = new URLSearchParams();
  filters.forEach((fv) => {
    params.append(fv.type, fv.value);
  });
  return params;
};

//Modifies single query string if search params is specified otherwise
//returns new query string with one query variable set
export const setQueryStringValue = (
  queryString: string,
  value: string,
  searchParams?: ReadonlyURLSearchParams | URLSearchParams
) => {
  const params = new URLSearchParams(searchParams);
  params.set(queryString, value);
  return params.toString();
};

//Modifies single query string if search params is specified otherwise
//returns new query string with one query variable set
export const setQueryStringValues = (
  queryStrings: Array<{ queryString: string; value: string }>,
  searchParams?: ReadonlyURLSearchParams | URLSearchParams
) => {
  const params = new URLSearchParams(searchParams);
  queryStrings.forEach((qs) => {
    params.set(qs.queryString, qs.value);
  });
  return params.toString();
};
