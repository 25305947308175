import { classNames } from "@/utils/styling-utils";
import { ReactNode } from "react";

interface BigColoredTextProps {
  color?: "blue" | "green"; // blue er default
  children: ReactNode;
}

export const BigColoredText = ({ color, children }: BigColoredTextProps) => (
  <div className={classNames("big-colored-text", color && `big-colored-text__color--${color}`)}>{children}</div>
);
